import en from './en.json';
import es from './es.json';
import ko from './ko.json';

//SPANISH AND KOREAN TRANSLATIONS FOR TESTING ONLY THEY ARE MADE BY A ROBOT!!!!!

export const languages = [
  {
    key: 'en-US',
    description: 'English',
    translation: en,
  },
  {
    key: 'es',
    description: 'Espanol',
    translation: es,
  },
  {
    key: 'ko',
    description: '한국어',
    translation: ko,
  },
];
